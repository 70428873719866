<template>
    <div class="reset-mfa-content">
        <section>
            <div class="reset-mfa-images">
                <img src="https://devcol.blob.core.windows.net/external-images/reset.svg"
                     alt="We sent an reset-mfa to your account, please review and follow the instructions to reset your MFA">
            </div>
            <div class="reset-mfa-message">
                <p>Your MFA code was successfully reset. Please try to login to the application again.</p>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'MFAReset'
};
</script>

<style scoped lang="scss">

.reset-mfa-content {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reset-mfa-images {
    display: flex;
    position: relative;
    justify-content: center;
}

.reset-mfa-images img {
    max-width: 400px;
}

.reset-mfa-message {
    width: 50%;
    margin: 30px auto;
    font-size: 20px;
    text-align: center;
}

@media only screen and (max-width: 520px) {
    .reset-mfa-images img {
        max-width: 200px;
    }
}
</style>
